import { useState } from 'react';

function App() {
  const [input, setInput] = useState('');

  const reverseText = (text) => {
    const lines = text.split(/\r?\n/);
    const reversedLines = lines.map((line) => {
      return line.split('').reverse().join('');
    });
    return reversedLines.join('\n');
  };

  const reversedInput = reverseText(input);

  return (
    <div className="p-10 w-full h-full flex flex-col items-center justify-center">
      <div className="flex flex-col sm:flex-row w-full h-full justify-start gap-2">
        <div className="flex w-full sm:w-1/2 h-full flex-col ">
          <div className="text-3xl text-center">Input</div>
          <textarea
            className="rounded-xl h-full p-4 text-left w-full border-2 border-black border-solid text-xl text-right"
            placeholder="הפוך"
            value={input}
            onChange={(event) => {
              setInput(event.target.value);
            }}
          />
        </div>
        <div
          className="h-1 w-full my-10 sm:hidden"
          style={{ background: '#9F9F9F' }}
        ></div>
        <div className="flex w-full sm:w-1/2 h-full flex-col">
          <div className="text-3xl text-center">Output</div>
          <div className="rounded-xl p-4 whitespace-pre-wrap h-full w-full border-2 border-black border-solid overflow-y-scroll text-xl text-right">
            {reversedInput}
          </div>
        </div>
      </div>
      <div
        className={`${
          input.length > 0
            ? 'bg-blue cursor-pointer'
            : 'bg-gray cursor-not-allowed'
        } text-white mt-10 text-3xl flex justify-center items-center rounded-xl p-4`}
        onClick={() => {
          navigator.clipboard.writeText(reversedInput);
        }}
      >
        Copy To Clipboard
      </div>
    </div>
  );
}

export default App;
